
import { BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  useParams,
  useRouteMatch } from "react-router-dom";
import City from "./components/city/City";
import Validate from "./components/city/Validate";
import './App.css';
import ProtectedRoute from "./components/ProtectedRoute";
import { Provider } from 'react-redux';


import React, { Component } from "react";

import UpdateCustList from "./components/Smallcase/UpdateCustList/UpdateCustList"; 
import SendNewsLetter from "./components/Smallcase/SendNewsLetter/SendNewsLetter";
import KYCFetch from './components/KYCFetch/KYCFetch';

import PortfolioDownload from "./components/PortfolioDownload/PortfolioDownload";
import TradeFile from './components/TradeFile/SendEmail'

import "./App.css"
//import Smallcase from './components/Smallcase/Smallcase'
import { store } from './store';
import config from './config';

function App() {
  console.table(config);
    return (
        <div>
         <Provider store={store}>
            <Router>
                <Route exact path='/signin' strict exact component={Validate} />
                <ProtectedRoute exact path='/'  component={City} />
                <ProtectedRoute exact path='/download_portfolio'  component={PortfolioDownload} />
                <ProtectedRoute exact path='/trade_file'  component={TradeFile} />
                {/* <ProtectedRoute exact path='/custdetails'  component={CustomerCANorPhone} /> */}
                <Switch>
                    <ProtectedRoute path={`/smallcase/updatecustlist`} component={withRouter(UpdateCustList)}/>                    
                    <ProtectedRoute path={`/smallcase/sendnewsletter`} component={withRouter(SendNewsLetter)}/>
                    <ProtectedRoute path={`/smallcase/kycfetch`} component={withRouter(KYCFetch)}/> 
                </Switch>
        </Router>
      </Provider>
    </div>

    );
}

export default App;
