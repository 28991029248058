import React, { Component } from 'react';

import {
    BrowserRouter as Router, Route, Switch, Link, useParams,
    useRouteMatch
} from "react-router-dom";

import style from "./Smallcase.module.scss"


function Smallcase(props) {
    
  const handleLogout = () =>{
    localStorage.clear();
    window.location.pathname="/signin";
  };

    return (
        <>
            <nav className={style.Nav}>
             <ul className={style.NavMenu}>
                <li className="nav-item active">
                    <Link to="/" className="nav-link" >

                        <span>Tools</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={"/smallcase/" + "updatecustlist"} className="nav-link">

                        <span>Update Customer List</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={"/smallcase/" + "sendnewsletter"} className="nav-link" >

                        <span>Send Newsletter</span>
                    </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/smallcase/" + "kycfetch"}  className="nav-link">
                    <span>KYC Fetch</span>
                </Link>
                </li>
                <li className="nav-item">
                    <Link to="/download_portfolio" className="nav-link" >

                        <span>Portfolio Download</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/trade_file" className="nav-link" >

                        <span>Trade File</span>
                    </Link>
                </li>
                
             </ul>
             <button className={style.Logout} onClick={handleLogout}>Logout</button>
            </nav>
        </>

    );
}



export default Smallcase;





