import './KYCFetch.scss';
import React, { Component } from 'react';
//import { Fabric } from '@fluentui/react/lib/Fabric';
//import { DefaultButton } from '@fluentui/react/lib/Button';
//import XLSX from 'xlsx';
//import { make_cols } from './MakeColumns';
//import { SheetJSFT } from './types';
import axios from 'axios';
import style from './KYCIndividualForm.module.scss';
import { IdentityDetails, AddressDetails, Instructions, DelcarationSection, OfficeUseSection } from "./KYCIndividual/KYCIndividualFormUtil/KYCIndividualFormUtil";
import html2canvas from 'html2canvas';
//import moment from 'moment';
//import { get } from "lodash";
import jsPDF from 'jspdf';
//import ReactDOMServer from "react-dom/server";
import 'bootstrap/dist/css/bootstrap.css';
import { InvData } from './InvDataUserList/InvData'
import { KYCFetchFailed } from './InvDataUserList/KYCFetchFailed'
import dummy from './dummy_data_pdf.json'
import Smallcase from '../Smallcase/Smallcase';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      data: [],
      cols: [],
      kycformData: [],
      load: true,
      downloadComplete: false,
      kycFailed: [],
      pan_fetch_count: 0,
      pan_failed_count: 0,
      invalid_data_excel: [],
      kyc_falied_list: [],
      count: 0,
      finished: false,
      validUserListLength: 0,

    }
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.sleep = this.sleep.bind(this);
    this.fetchKycValidUser = this.fetchKycValidUser.bind(this);

  }
  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  fetchData(data) {
    //  console.log("I am in fetch data function")
    //  console.log(data.insert_data,"data.insert_data")
    //   this.setState({ 
    //     kycformData: data.insert_data,
    //   });
    //   this.setState({ load: false });
    //   let id="individualkycform" ;
    //   let HTML_Width =  800;
    //   let HTML_Height =  1500;
    //   let top_left_margin = 15;
    //   let PDF_Width = HTML_Width+(top_left_margin*2);
    //   let PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
    //   let canvas_image_width = HTML_Width;
    //   let canvas_image_height = HTML_Height;

    //   let totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
    //   let pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height+10]);

    //   window.scrollTo(0, 0);
    //   let g =  html2canvas(document.getElementById(id),{allowTaint:true}).then(function(canvas) {
    //     canvas.getContext('2d');

    //     let imgData = canvas.toDataURL("image/jpeg", 1.0);


    //     pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);

    //     for (let i = 1; i <= totalPDFPages; i++) {
    //       pdf.addPage(PDF_Width, PDF_Height);
    //       pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin),canvas_image_width,canvas_image_height);
    //     }

    //       pdf.save("somedummy" + ".pdf");


    //      }); 
    //      this.setState({ pan_fetch_count: this.state.pan_fetch_count + 1 }); 


    console.log(data, "data")
    const value = [data.PAN, data.DOB]
    // console.log(value, "value")
    this.setState({ count: this.state.count + 1 })

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ value: value })
    };
    fetch('https://api.lotusdew.in/kyc/fetches', requestOptions)
      .then(response => response.json())
      .then(res => {
        // console.log(res.success,"res.sucess")
        if (res.success === true) {
          //  console.log(res.insert_data,"res.insert_data");
          this.setState({ kycformData: res.insert_data });
          this.setState({ load: false });
          let id = "individualkycform";
          let HTML_Width = 800;
          let HTML_Height = document.getElementById(id).offsetHeight;
          // console.log(HTML_Height,"HTML_Height")
          let top_left_margin = 15;
          let PDF_Width = HTML_Width + (top_left_margin * 2);
          let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
          let canvas_image_width = HTML_Width;
          let canvas_image_height = HTML_Height;

          let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

          window.scrollTo(0, 0);
          let g = html2canvas(document.getElementById(id), { allowTaint: true }).then(function (canvas) {
            canvas.getContext('2d');

            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height + 10]);
            pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

            for (let i = 1; i <= totalPDFPages; i++) {
              pdf.addPage(PDF_Width, PDF_Height);
              pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin), canvas_image_width, canvas_image_height);
            }
            pdf.save(value[0] + ".pdf");
          });
          this.setState({ pan_fetch_count: this.state.pan_fetch_count + 1 });
        }
        else {
          alert(`failed to download user KYC having PAN ${data.PAN}`)
        }
      })
      .catch(error => {
        console.log("error", error)
        this.setState({
          kyc_falied_list: [...this.state.kyc_falied_list,
          {
            PAN: data.PAN,
            DOB: data.DOB,
            RefNo: data.RefNo,
            POSCODE: 2500013770
          }
          ]
        })

        this.setState({ pan_failed_count: this.state.pan_failed_count + 1 });
        this.setState(prevState => ({
          kycFailed: [...prevState.kycFailed, value[0]]
        }))
      })
  }

  async fetchKycValidUser(res) {

    this.setState({
      validUserListLength: res.length
    })

    for (var i = 0; i < res.length; i++) {
      await this.fetchData(res[i]);
      await this.sleep(15000);


    }
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.setState({ file: files[0] });
  };

  handleFile() {

    // /* Boilerplate to set up FileReader */
    // const reader = new FileReader();
    // const rABS = !!reader.readAsBinaryString;


    // reader.onload = async (e) => {
    //   /* Parse data */
    //   const bstr = e.target.result;
    //   const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true, cellText:false, cellDates:true });
    //   /* Get first worksheet */
    //   const wsname = wb.SheetNames[0];
    //   const ws = wb.Sheets[wsname];
    //   /* Convert array of arrays */
    //   const data = XLSX.utils.sheet_to_json(ws, {header:1, raw:false});

    //   /* Update state */
    //   this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
    //     this.setState({ data: this.state.data.slice(1) });
    //   });


    //         console.log(this.state.data, 'data')
    //         const format = (day, month, year) => {
    //             //var date = new Date(value.replace("IST", ""));

    //             //let day = date.getDate();
    //             //let month = date.getMonth() + 1;
    //             //let year = date.getFullYear();
    //             let final = day + "/" + month + "/" + year;
    //             return final;
    //         }


    //         //To access the invalid data:
    //         const panArray = this.state.data.map(data => {

    //             return {
    //                 PAN: data[3],
    //        DOB:  format(new Date(data[4]).getDate(), new Date(data[4]).getMonth()+1, new Date(data[4]).getFullYear()),
    //       Name: data[0],
    //       RefNo : data[2],
    //       Email: data[1],
    //     };

    //   }); 
    // .post('https://api.lotusdew.in/kyc/new_list', { data: panArray })


    // this.fetchKycValidUser(dummy.data);

    fetch('https://chat-crm.lotusdew.in/smallcase/new_list')
      .then(response => response.json())
      .then(res => {

        console.log(res, "new list")
        this.fetchKycValidUser(res.data);
        this.setState({
          invalid_data_excel: res.invalid_data
        });
      }).catch((error) => {
        //console.log(error);
      });

    this.setState({ downloadComplete: true });




  };

  // if (rABS) {
  //   reader.readAsBinaryString(this.state.file);
  // } else {
  //   reader.readAsArrayBuffer(this.state.file);
  // };
  //}




  render() {

    const { kycformData, load, downloadComplete, kycFailed, invalid_data_excel, kyc_falied_list, finished, validUserListLength } = this.state;
    console.log(validUserListLength, "validUserListLength")
    let invalid_data_excel2 = invalid_data_excel.map(function (item) {
      delete item.Email;
      return item;
    });
    const total_time = validUserListLength * 7;
    //  console.log("dummy.data",dummy.data)
    //   console.log(kycformData,"formdata")
    return (
      <div>
        <Smallcase />
        {/* <label htmlFor="file">Upload an excel file containing list of pan and dob of the kyc to be fetched</label>
        <br />
        <input type="file" className="form-control" id="file" accept={SheetJSFT} onChange={this.handleChange} />
        <br />*/}
        <div className={style.cont}>
          <button
            className={style.btn}
            type='submit'
            value="Fetch"
            onClick={this.handleFile} >Fetch</button>
          <p>
            {validUserListLength !== 0 ? `Please wait for ${total_time} seconds to download ${validUserListLength} KYC pdf` : ''}
            {/* { downloadComplete ? 'Your download is completed' : 'Once clicked on submit please wait till we download all the pdfs' } */}
          </p>
          <p>
            {downloadComplete ? 'Total no of kyc fetched is ' + this.state.pan_fetch_count : ''}</p>
          <p>
            {downloadComplete ? 'Total no of fails is ' + this.state.pan_failed_count : ''}</p>
          {load ? '' : <div className={`${style.kycHide}`}>
            <div id="individualkycform" className={`${style.kycForm}`}>
              <div className={`${style.formBody} m-4`}>
                <div className={`${style.headerSecGap}`} ></div>
                <div className="pt-3" >
                  <div className="float-left ml-5 mr-2">
                    <span>Know Your Client(KYC) <br />
                      Application Form (For Individuals Only)</span>
                    <hr className={`${style.headerHrLine}`} />
                    <span >Please Fill in English and in BLOCK LETTERS</span>
                  </div>
                  <div className="float-left mr-2">
                    <img src="/static/images/KycindividualLogo.JPG"
                      className={`${style.fixedH67}`} />
                  </div>
                  <div className="float-left pt-2">
                    <div
                      className={`${style.internmediaryLogo} internmediary-logo mr-3 float-left`} >
                      Place for internmediary Logo
                    </div>
                    <span >Application No.: {kycformData.application_kyc_data.application_no}</span>
                  </div>
                </div>


                <IdentityDetails kycformData={kycformData} ></IdentityDetails>
                <AddressDetails kycformData={kycformData} ></AddressDetails>
                <DelcarationSection kycformData={kycformData}></DelcarationSection>
                <OfficeUseSection kycformData={kycformData}></OfficeUseSection>
                <Instructions></Instructions>
              </div>
            </div>
          </div>
          }
          {/*{ kycFailed.length ? 'Kyc fetch failed for pans' : ''}*/}
          {/*{kycFailed.map(function(pan, index){
                    return <li key={ index }>{pan}</li>;
         })}*/}

          <InvData apiData={invalid_data_excel2} />

          <KYCFetchFailed kycData={kyc_falied_list} />
        </div>
      </div>
    )
  }
}

export default App;







